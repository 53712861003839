import React from 'react'
import logo from '../assets/images/rxlogo.png'
import Footer from '../components/Footer'
import Top from '../components/Top'

const Home: React.FC = () => {
  return (
    <>
      <Top></Top>
      <div
        style={{
          fontFamily: 'Arial, sans-serif',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: 'calc(100vh - 400px)',
          flexDirection: 'column',
          backgroundColor: '#f4f4f4',
        }}
      >
        <div
          id="logo"
          style={{
            width: '130px',
            height: '140px',
            // borderRadius: '50%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            marginBottom: '20px',
            backgroundImage: `url(${logo})`,
            backgroundSize: 'cover',
          }}
        >
          {/* O conteúdo de texto "RX" foi removido, pois agora você tem a imagem do logo */}
        </div>
        <h1>RX Code Consultoria e Desenvolvimento de Sistemas LTDA</h1>
        <p>Site em construção</p>
        <p>CNPJ: 31.173.692/0001-01</p>
      </div>
      <Footer></Footer>
    </>
  )
}

export default Home
