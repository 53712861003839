import React from 'react'
import styled from 'styled-components'

const FooterContainer = styled.footer`
  background-color: #333;
  color: white;
  width: 100%;
  position: fixed;
  bottom: 0;
  display: flex;
  justify-content: space-around; // Ajuste para espaço igual entre os blocos
  align-items: center;
  padding: 1rem 2rem; // Ajuste para padding vertical
  height: 400px;
  font-family: 'Roboto', sans-serif;
  flex-wrap: wrap; // Permitir que os itens envolvam para a próxima linha em telas menores

  @media (max-width: 768px) {
    flex-direction: column; // Ajuste para dispositivos móveis
    justify-content: center;
  }
`

const LinkList = styled.ul`
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  gap: 1rem;
`

const LinkItem = styled.li`
  cursor: pointer;
`

const CopyrightText = styled.p`
  text-align: center; // Centralizar o texto de copyright
`

const Footer: React.FC = () => {
  return (
    <FooterContainer>
      <LinkList>
        <LinkItem>Sobre a empresa</LinkItem>
        <LinkItem>Nossos contatos</LinkItem>
        <LinkItem>Atendimento</LinkItem>
      </LinkList>

      <CopyrightText>
        © {new Date().getFullYear()} RX Code Consultoria e Desenvolvimento de
        Sistemas LTDA
      </CopyrightText>

      <LinkList>
        <LinkItem>Facebook</LinkItem>
        <LinkItem>Whatsapp</LinkItem>
        <LinkItem>Instagram</LinkItem>
        <LinkItem>Twitter</LinkItem>
      </LinkList>
    </FooterContainer>
  )
}

export default Footer
