import React from 'react'
import styled from 'styled-components'
import logo from '../assets/images/rxlogo.png'

const NavbarContainer = styled.nav`
  @media (max-width: 768px) {
    /* styles for mobile devices */

    flex-direction: column;
    padding: 0.5rem;
    text-align: center;
  }

  height: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  position: relative;
  max-width: 1111px;
  padding: 1rem;
  margin: 0 auto;
`

const Logo = styled.img`
  height: 40px;
`

const PrivacyLink = styled.a`
  color: navy;
  margin-right: 1rem;
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
`

const ServicesButton = styled.button`
  background-color: navy;
  color: white;
  border: none;
  padding: 0.5rem 1rem;
  border-radius: 0.25rem;
  cursor: pointer;
  &:hover {
    background-color: darkblue;
  }
`

const Top: React.FC = () => {
  return (
    <NavbarContainer>
      <Logo src={logo} alt="Logo da Empresa" />
      <div>
        <PrivacyLink href="/politics">Política de privacidade</PrivacyLink>
        <ServicesButton>Conheça nossos serviços</ServicesButton>
      </div>
    </NavbarContainer>
  )
}

export default Top
