import React from 'react'
import logo from './rxlogo.png' // Ajuste o caminho conforme a localização do seu arquivo
import './App.css'
import Home from './pages/Home'

function App() {
  return <Home></Home>
}

export default App
